const createScript = (src) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = src
		script.async = true
		script.id = 'ze-snippet'
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})
  }

export default {
    install(app) {

		const queue = []
		const fakezE = (...args) => {
			queue.push(args)
		}

        app.prototype.$zendesk = {
            async initialize(data) {
				try {
					const API_KEY = process.env.VUE_APP_ZENDESK_API_KEY
					const script = await createScript(`https://static.zdassets.com/ekr/snippet.js?key=${API_KEY}`)
					console.log('Zendesk script loaded', script)
				} catch (e) {
					console.error('Failed to load Zendesk script', e)
				}

				window.zE('messenger', 'logoutUser')
				const formatted = Object.entries(data).map(([id, value]) => ({ id, value }))
				window.zE('messenger:set', 'conversationFields', formatted)

				if (queue.length) {
					queue.forEach(args => window.zE(...args))
				}
			  },
			  get zE() {
				if (!window.zE) {
					return fakezE
				}
				return window.zE
			  }
        }
    }
}
